import React from "react"
import Layout from "../components/layout"
import "../stylesheets/pages/screenshots.css"
import ScreenshotsMobileCarousel from "../components/screenshots-mobile-carousel"

import img1 from "../components/images/MPfull_1.png"
import img2 from "../components/images/image1.png"
import img3 from "../components/images/image2.png"
import img4 from "../components/images/image3.png"
import img5 from "../components/images/image5.png"
import img6 from "../components/images/image6.png"


export default function Sreenshots() {
  return (
    <Layout>
      <div className="bigWrapper mob">
        <div className="imageMP mob"><img id="MpImg" className="mob" src="/MPfull_1.png" alt="pic" /></div>
      </div>
      <div className="screenshotsInfo mob">
      <p>We were approached to create an early stage mock-up for MatchPoint.</p>
      <p>MatchPoint is a marketplace platform to create connections between NCAA athletes and businesses.</p>
      <p>We created an athlete facing mobile app mock-up and a business facing web app mock-up.</p>
      </div>

      <div className="bottomHalf">
        <div className="left mob">
          <div className="titleSplit mob">Athlete Facing Mobile App</div>
          <div class="rowSS">
            <ScreenshotsMobileCarousel />
          </div>
        </div>
        <div className="right mob">
          <div className="titleSplit mob">Business Facing Web App</div>
            <img className="rightImgTop mob" id="SS_Wide" src={img5} alt="pic" />
            <img className="rightImgBot mob" id="SS_Wide" src={img6} alt="pic" />
          <div className="rightDes mob">Connect and communicate with athletes.</div>
        </div>
      </div>

    </Layout>
  );
}
