import React from "react"

import img1 from "./images/MPfull_1.png"
import img2 from "./images/image1.png"
import img3 from "./images/image2.png"
import img4 from "./images/image3.png"
import img5 from "./images/image5.png"
import img6 from "./images/image6.png"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

export default class ScreenshotsMobileCarousel extends React.Component {

  render() {
    return (
      <div className="Root">
          <div className="rowSS MobileScreenshots">
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={true}
              className=""
              containerclassName="container-with-dots"
              dotListclassName=""
              draggable
              focusOnSelect={false}
              infinite
              itemclassName=""
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={true}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024
                  },
                  items: 3,
                  partialVisibilityGutter: 20
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0
                  },
                  items: 1,
                  partialVisibilityGutter: 20
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464
                  },
                  items: 2,
                  partialVisibilityGutter: 20
                }
              }}
              sliderclassName=""
              slidesToSlide={1}
              swipeable
          >
              <div>
                  <img className ="SS_Tall_M" src={img2} />
                  <p className="legend">Communicate directly with brands.</p>
              </div>
              <div>
                  <img className ="SS_Tall_M" src={img3} />
                  <p className="legend">Custom profile pages.</p>
              </div>
              <div>
                  <img className ="SS_Tall_M" src={img4} />
                  <p className="legend">Find projects to be a part of.</p>
              </div>
          </Carousel>
        </div>


          <div className="rowSS DesktopScreenshots">
            <div className="columnSS">
              <img id="SS_Tall" className="mob" src={img2} alt="pic" />
              <div className="description mob">Communicate directly with brands.</div>
            </div>
            <div className="columnSS">
              <img id="SS_Tall" className="mob" src={img3} alt="pic" />
              <div className="description mob">Custom profile pages.</div>
            </div>
            <div className="columnSS">
              <img id="SS_Tall" className="mob" src={img4} alt="pic" />
              <div className="description mob">Find projects to be a part of.</div>
            </div>
          </div>
      </div>
      )
  }
}
